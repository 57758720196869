<template>
    <section>
        <div class="section login-2 py-5 " style="margin-top:400px;">
            <div class="container">   
                <Pano type='video' source="test.mp4"></Pano>    
            </div>
        </div>   
    </section>
</template>

<script>
import { Pano } from 'vuejs-vr'

export default {
    components: { Pano }
}
</script>